import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import SearchBlock from "components/search/SearchBlock";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { autobind } from "react-decoration";
import { page } from "components/page";
import Link from "lib/Link";
import ReactHtmlParser from "react-html-parser";

const getFaqList = gql`
  query getFaqList {
    result: getFaqList {
      list {
        sn
        quest
        answer
        creTime
        updTime
        urls
        attachs {
          showName
          attachName
        }
      }
    }
  }
`;

@withI18next(["common"])
@page
class Faq extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resourceType: "databases",
      listContent: {},
    };
  }

  componentDidMount() {
    this.getList(this.props);
  }

  @autobind
  getList(props) {
    client.jumperrwdClient
      .query({
        query: getFaqList,
      })
      .then((res) => {
        console.log("test!!!");
        let { result } = res.data;
        console.log(result);

        //<td>{this.props.t("jumper.common.faq"+id)}</td>

        this.setState({ listContent: result });
      });
  }

  render() {
    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <SearchBlock />
          <section className="main_content">
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accessKey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <h2>{this.props.t("jumper.common.faq")}</h2>
              <a href="javascript:history.back();" className="back">
                {this.props.t("hyint.common.table.back")}
              </a>
              {this.state.listContent.list &&
                this.state.listContent.list.map((field, index) => {
                  return (
                    <div className="detail_block">
                      <h3>{ReactHtmlParser(field.quest)}</h3>
                      <div className="date_panel">
                        <div className="publish_time">
                          <span>
                            {this.props.t("hyint.common.table.credate")}
                          </span>
                          <time>
                            {field.creTime.substring(0, 4)}/
                            {field.creTime.substring(4, 6)}/
                            {field.creTime.substring(6, 8)}
                          </time>
                        </div>
                        {field.updTime && (
                          <div className="publish_time">
                            <span>
                              {this.props.t("hyint.common.table.upddate")}
                            </span>
                            <time>
                              {field.updTime.substring(0, 4)}/
                              {field.updTime.substring(4, 6)}/
                              {field.updTime.substring(6, 8)}
                            </time>
                          </div>
                        )}
                      </div>
                      <div className="article_content">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: field.answer,
                          }}></p>

                        {field.urls &&
                          field.urls.split("\r\n").map((items, i) => {
                            let data = items.split(";");
                            if (data[0] === "1") {
                              return (
                                <p>
                                  <Link href={data[2]} target="_blank">
                                    {data[1]}
                                  </Link>
                                </p>
                              );
                            } else {
                              return (
                                <p>
                                  <Link href={data[2]}>{data[1]}</Link>
                                </p>
                              );
                            }
                          })}

                        {field.attachs &&
                          field.attachs.map((item) => {
                            return (
                              <p>
                                <Link href={item.attachName} className="link">
                                  {item.showName}
                                </Link>
                              </p>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Faq;
